import { useEffect } from 'react';

import { BackArrowIcon } from 'assets/icons';

import OutgrowerProfileCreateForm from 'entities/Outgrower/ProfileCreateForm';

import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerCreatePage = () => {
  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(
      <PageTitle text="Create OG Profile" icon={<BackArrowIcon />}></PageTitle>
    );

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const handleSave = (data: any) => {
    console.log('hanldesave', data);
    return Promise.resolve();
  };

  return (
    <div className="h-full px-[26px] py-5">
      <OutgrowerProfileCreateForm onSave={handleSave} />
    </div>
  );
};

export default OutgrowerCreatePage;
