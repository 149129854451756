import { TractorIcon } from 'assets/icons';

import { FormProps } from 'entities/Outgrower/ProfileCreateForm';

import Text from 'components/Text';
import TextField from 'components/TextField';

import { UseFormRegister } from 'utils/forms';

interface IFarmInformationProps {
  register: UseFormRegister<FormProps>;
}

const FarmInformation = ({ register }: IFarmInformationProps) => {
  console.log('register', register);
  return (
    <div className="flex flex-1 flex-col gap-3">
      <Text
        classes={{
          root: 'text-xl font-semibold  text-wet-green leading-8 tracking-[0.15px]'
        }}
      >
        Farm Information
      </Text>
      <div className="flex flex-col gap-6 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <TractorIcon />
          <Text className="font-base font-semibold leading-[19.2px]">Farm</Text>
        </div>
        <div className="flex gap-3">
          <div className="flex w-1/2 flex-col gap-4">
            <TextField
            // {...register('farm_name')}
            // label="Farm name*"
            // type="text"
            />
            <TextField
            // {...register('irrigation')}
            // label="Irrigation*"
            // type="text"
            />
            <TextField
            // {...register('farm_area')}
            // label="Region/ State / Province (if applicable)"
            // type="text"
            />
            <TextField
            // {...register('farm_street')}
            // label="Street"
            // type="text"
            />
          </div>
          <div className="flex w-1/2 flex-col gap-4">
            <TextField
            // {...register('farm_size')}
            // label="Farm size (ha)"
            // type="number"
            />
            <TextField
            // {...register('farm_country')}
            // label="Country*"
            // type="number"
            />
            <TextField
            //  {...register('farm_town')} label="Town*" type="text"
            />
            <TextField
            // {...register('farm_street_number')}
            // label="Street number"
            // type="text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmInformation;
