import {
  FileUploadIcon,
  PeopleBlackIcon,
  PhoneIcon,
  PhotoAddIcon
} from 'assets/icons';

import { FormProps } from 'entities/Outgrower/ProfileCreateForm';

import Avatar from 'components/Avatar';
import { FormControl, FormControlLabel } from 'components/Form';
import { Radio, RadioGroup } from 'components/Radio';
import Text from 'components/Text';
import TextField from 'components/TextField';

import { UseFormRegister } from 'utils/forms';

interface IOutgrowerInformationProps {
  register: UseFormRegister<FormProps>;
}

const OutgrowerInformation = ({ register }: IOutgrowerInformationProps) => {
  console.log('register', register);
  return (
    <div className="flex flex-1 flex-col gap-3">
      <Text
        classes={{
          root: 'text-xl font-semibold  text-wet-green leading-8 tracking-[0.15px]'
        }}
      >
        Outgrower Information
      </Text>
      <div className="flex flex-col gap-6 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-6">
          <Avatar className="h-[90px] w-[90px] cursor-pointer border border-dashed border-fresh-green bg-white">
            <FileUploadIcon />
          </Avatar>
          <div className="flex flex-1 flex-col gap-2">
            <div className="flex items-center gap-2">
              <PhotoAddIcon />
              <Text className="text-base font-semibold leading-[19.2px]">
                Profile photo
              </Text>
            </div>
            <div>
              <Text className="text-sm font-bold leading-[21px]">
                Click the icon to upload profile picture
              </Text>
              <Text className="text-xs font-normal leading-[21px]">
                PNG or JPG (max. 5 MB)
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-2">
            <PhoneIcon />
            <Text className="font-base font-semibold leading-[19.2px]">
              Contacts
            </Text>
          </div>
          <div className="flex gap-3">
            <div className="flex w-1/2 flex-col gap-4">
              <TextField
              // id="first_name"
              // label="First name*"
              // type="text"
              // {...register('first_name')}
              />
              <TextField
              // {...register('email')} label="Email*" type="email"
              />
              <TextField
              // {...register('company_name')}
              // label="Company name(if applicable)"
              // type="text"
              />
              <TextField
              // {...register('area')}
              // label="Region/ State / Province (if applicable)"
              // type="text"
              />
              <TextField
              // {...register('street')} label="Street" type="text"
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <TextField
              // {...register('last_name')}
              // label="Last name*"
              // type="text"
              />
              <TextField
              // {...register('phone_number')}
              // label="Phone number*  "
              // type="number"
              />
              <TextField
              // {...register('country')}
              // label="Country*"
              // type="text"
              />
              <TextField
              // {...register('town')} label="Town*" type="text"
              />
              <TextField
              // {...register('house_number')}
              // label="House number"
              // type="text"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <PeopleBlackIcon />
            <Text className="font-base font-semibold leading-[19.2px]">
              Lead farmer
            </Text>
          </div>
          <div className="flex items-center">
            <Text className="w-1/2 text-sm font-normal leading-[16.8px]">
              Is this Outgrower a Lead farmer?
            </Text>
            <FormControl className="w-1/2 px-6">
              <RadioGroup row={true} name="lead_farmer" className="gap-6">
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgrowerInformation;
