import { useFetch } from 'utils/sdk';

export interface ContractsDashboardListResponse {
  id: number;
  farm_name: string;
  farm_location: string;
  manager: string;
  area: number;
  crop: string;
}

export const useContractsDashboardList = () =>
  useFetch<Array<ContractsDashboardListResponse>>(
    `/api/contracts/dashboard/list/`
  );
