import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import TextField, { ITextField } from 'components/TextField';

interface IFormTextField<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  fieldProps: ITextField;
}

const FormTextField = <T extends FieldValues>({
  name,
  control,
  fieldProps
}: IFormTextField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <TextField {...fieldProps} {...field} />}
    />
  );
};

export default FormTextField;
