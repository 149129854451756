import { Link } from 'react-router-dom';

import { LeftArrowIcon } from 'assets/icons';

import Text from 'components/Text';

import { URLS } from 'config/urls';

const BackButton = () => {
  return (
    <Link to={URLS.OUTGROWERS} className="flex items-center py-2 opacity-50">
      <div className="flex items-center gap-1">
        <LeftArrowIcon />
        <Text className="text-xs font-medium text-white">Outgrowers</Text>
      </div>
    </Link>
  );
};

export default BackButton;
