import { SortUpArrow } from 'assets/icons';
import { twMerge } from 'tailwind-merge';

import { ContractsDashboardListResponse } from 'entities/Contracts/sdk';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from 'components/Table';

interface IDashboardListTable {
  contracts: Array<ContractsDashboardListResponse>;
}

interface ITableCell {
  content: string | number;
  className?: string;
}

const TableHeaderCell = ({ content, className }: ITableCell) => (
  <TableCell
    component="th"
    key={content}
    align="center"
    classes={{
      root: twMerge(
        'px-[17px] py-3 text-base font-semibold	leading-6	tracking-[0.15px]',
        className
      )
    }}
  >
    <TableSortLabel
      active={true}
      IconComponent={() => (
        <div className="ml-[4px] flex flex-1 items-center">
          <SortUpArrow />
        </div>
      )}
    >
      {content}
    </TableSortLabel>
  </TableCell>
);

const TableBodyCell = ({ content, className }: ITableCell) => (
  <TableCell
    align="center"
    classes={{
      root: twMerge('p-5 text-xs font-normal leading-[14px]', className)
    }}
  >
    {content}
  </TableCell>
);

const DashboardListTable = ({ contracts }: IDashboardListTable) => {
  return (
    <TableContainer
      classes={{
        root: 'p-4 rounded-xl border border-solid border-[#d4dae1] shadow-sm	shadow-[rgba(16, 24, 40, 0.05)] bg-white'
      }}
    >
      <Table classes={{ root: 'border-solid border border-[#c5c5c5]/30' }}>
        <TableHead>
          <TableRow>
            <TableHeaderCell content="Contract ID" className="w-[107px]" />
            <TableHeaderCell content="Farmer" />
            <TableHeaderCell content="Farm" />
            <TableHeaderCell content="Lead Farmer" />
            <TableHeaderCell content="Location" />
            <TableHeaderCell content="Size" />
            <TableHeaderCell content="Crop" />
            <TableHeaderCell content="Growth Stage" className="w-[97px]" />
            <TableHeaderCell content="Exposure" />
            <TableHeaderCell content="Harvest" />
            <TableHeaderCell content="Weekly Task" className="w-[130px]" />
          </TableRow>
        </TableHead>
        <TableBody>
          {contracts.map((contract) => (
            <TableRow
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10'
              }}
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={contract.manager}
                className="font-medium"
              />
              <TableBodyCell
                content={contract.farm_name}
                className="font-medium"
              />
              <TableBodyCell
                content={contract.manager}
                className="font-medium"
              />
              <TableBodyCell content={contract.farm_location} />
              <TableBodyCell content={contract.area} />
              <TableBodyCell content={contract.crop} />
              <TableBodyCell content={'Germination'} className="w-[97px]" />
              <TableBodyCell content={'$2,750'} />
              <TableBodyCell content={'200 t'} />
              <TableBodyCell content={'Weekly Task 1'} className="w-[130px]" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardListTable;
