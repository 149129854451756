import { BASE_URL, post } from 'utils/sdk';

export const login = ({
  email,
  password
}: {
  email: string;
  password: string;
}) => post(`${BASE_URL}/api/auth/login/`, { email, password });

export const logout = () => post(`${BASE_URL}/api/auth/logout/`);
