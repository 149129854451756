import { createTheme } from '@mui/material/styles';

import paletteColors from './colors.ts';

const theme = createTheme({
  palette: paletteColors,
  typography: {
    fontFamily: 'Roboto'
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        slotProps: {
          inputLabel: {
            shrink: true
          }
        }
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px',
            padding: '0px 12px',
            height: '44px',
            border: 'none'
          },
          '& .MuiOutlinedInput-input': {
            color: '#9E9E9E',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '0.15px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #BDBDBD'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '999px',
          padding: '7px 16px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20.02px',
          letterSpacing: '0.15px',
          textTransform: 'none',
          height: '34px',
          '&.MuiButton-containedWetGreen': {
            backgroundColor: '#062D29',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#062D29',
              boxShadow: 'None'
            }
          },
          '&.MuiButton-outlinedWetGreen': {
            backgroundColor: 'white',
            color: '#062D29',
            borderColor: '#062D29'
          }
        }
      }
    }
  }
});
export default theme;
