import { useNavigate } from 'react-router-dom';

import FarmInformation from 'entities/Outgrower/ProfileCreateForm/FarmInformation';
import OutgrowerInformation from 'entities/Outgrower/ProfileCreateForm/OutgrowerInformation';

import Button from 'components/Button';

import { useForm } from 'utils/forms';

import { URLS } from 'config/urls';

export interface FormProps {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  area: string;
  street: string;
  country: string;
  town: string;
  house_number: string;
  lead_farmer: string;
  irrigation: string;
  farm_name: string;
  farm_area: string;
  farm_size: string;
  farm_street: string;
  farm_country: string;
  farm_town: string;
  farm_street_number: string;
}
interface IProfileCreateFormProps {
  onSave: (data: FormProps) => Promise<any>;
}

const ProfileCreateForm = ({ onSave }: IProfileCreateFormProps) => {
  const navigate = useNavigate();

  const { register, handleSubmit, setError } = useForm<FormProps>();

  const onSubmit = (data: FormProps) => {
    onSave(data).catch(({ setFormErrors }) => setFormErrors(setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-3">
        <OutgrowerInformation register={register} />
        <FarmInformation register={register} />
      </div>

      <div className="flex justify-end gap-4">
        <Button
          variant="outlined"
          color="wetGreen"
          className="w-[178px]"
          onClick={() => navigate(URLS.DASHBOARD)}
        >
          Cancel
        </Button>
        <Button
          className="w-[178px]"
          type="submit"
          variant="contained"
          color="wetGreen"
        >
          Create Profile
        </Button>
      </div>
    </form>
  );
};

export default ProfileCreateForm;
