import {
  // Navigate,
  // useNavigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

import DashboardPage from 'pages/Dashboard';
import LoginPage from 'pages/Login';
import OutgrowerCreatePage from 'pages/OutgrowerCreate';
import OutgrowerProfilePage from 'pages/OutgrowerProfile';
import OutgrowersListPage from 'pages/OutgrowersList';

import AnonimousGuard from 'components/AnonimousGuard';
import AuthGuard from 'components/AuthGuard';
import DetailsLayout from 'components/Layout/DetailsLayout';
import MainLayout from 'components/Layout/MainLayout';

import { URLS } from 'config/urls';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AnonimousGuard />}>
        <Route path={URLS.LOGIN} element={<LoginPage />} />
      </Route>

      <Route element={<AuthGuard />}>
        <Route path="/" element={<MainLayout />}>
          <Route path={URLS.DASHBOARD} element={<DashboardPage />} />
          <Route path={URLS.OUTGROWERS} element={<OutgrowersListPage />} />
          <Route
            path={URLS.OUTGROWER_PROFILE}
            element={<OutgrowerProfilePage />}
          />
          <Route
            path={URLS.OUTGROWER_CREATE}
            element={<OutgrowerCreatePage />}
          />
          <Route path={URLS.OUTGROWER_CONTRACTS} element={<DashboardPage />} />
          <Route
            path={URLS.OUTGROWER_CONTRACT_CREATE}
            element={<DashboardPage />}
          />
          <Route path={URLS.CONTRACTS} element={<DashboardPage />} />

          <Route path="/" element={<DetailsLayout />}>
            <Route path={URLS.OUTGROWER_TASKS} element={<DashboardPage />} />
            <Route
              path={URLS.OUTGROWER_TASKS_GALLERY}
              element={<DashboardPage />}
            />
          </Route>
        </Route>
      </Route>
    </>
  )
);

const Routes = () => <RouterProvider router={router} />;
export default Routes;
