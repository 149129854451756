import { ReactNode } from 'react';

interface ITopNavigation {
  pageTitle: ReactNode;
  actionButton?: ReactNode;
}

const TopNavigation = ({ pageTitle, actionButton }: ITopNavigation) => {
  return (
    <div className="flex h-[66px] items-center justify-between bg-white px-5 py-2.5 shadow-[0_2px_0_-1px_#EEEEEE]">
      {pageTitle}
      {actionButton}
    </div>
  );
};

export default TopNavigation;
